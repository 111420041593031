import React from 'react'
import SectionTitle from '../../components/SectionTitle'
import { useSelector } from 'react-redux';

function ContactRu() {
    const { portfolioData } = useSelector((state) => state.root);
    const { contactrus } = portfolioData;


    // Scroll fade in
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        })
        const currentRef = domRef.current; // Copy the ref value to a variable
        if (currentRef) {
            observer.observe(currentRef);
        }
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        }
    }, []);


    return (
        <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
            <SectionTitle title="Скажите привет" />
            <div className='flex sm:flex-col si:text-sm text-xl items-center w-full justify-center space-x-[100px] ml-[40px] sm:space-x-1 sm:ml-0'>
                {/* Object Box */}
                <div className='flex flex-col gap-1'>
                    <p className='text-tertiary opacity-90'>{'{'}</p>
                    {Object.keys(contactrus).slice(1).map((key) => (
                        <p className='ml-5'>
                            {contactrus[key] !== '' && (<>
                                <span className='text-tertiary opacity-90'>{key}: </span>
                                <span className='text-tertiary opacity-90'>{contactrus[key]}</span>
                            </>)}
                        </p>
                    ))}
                    <p className='text-tertiary opacity-90'>{'}'}</p>
                </div>
                {/* Object Box */}
                {/* Animation Box */}
                <div className='h-[350px]'>
                    <dotlottie-player
                        src="https://lottie.host/efffca6e-1ca5-40c3-a5e4-400033760ed6/pib6Lak4Ix.json"
                        background="transparent"
                        speed="1"
                        loop
                        autoplay
                    ></dotlottie-player>
                </div>
                {/* Animation Box */}
            </div>
        </div>
    )
}

export default ContactRu