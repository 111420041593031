import React from 'react';
import SectionTitle from '../../components/SectionTitle';
import { useSelector } from 'react-redux';

function ProjectsRu() {
    const { portfolioData } = useSelector((state) => state.root);
    const { projectsrus } = portfolioData;
    const [SelectedItemIndex, setSelectedItemIndex] = React.useState(0);


    // Scroll fade in
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        })
        const currentRef = domRef.current; // Copy the ref value to a variable
        if (currentRef) {
            observer.observe(currentRef);
        }
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        }
    }, []);


    return (
        <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
            <SectionTitle title="Проекты" />
            {/* Entire Box */}
            <div className='flex py-10 gap-20 items-center sm:flex-col sm:gap-12 sm:py-5 md:flex-col md:gap-12'>
                {/* Left Side */}
                <div className='flex flex-col gap-10 border-l-4 border-[#59dfb73a] w-1/4 sm:flex-row sm:w-full sm:overflow-x-scroll md:flex-row md:w-full md:overflow-x-scroll'>
                    {projectsrus.map((project, index) => (
                        <div onClick={() => {
                            setSelectedItemIndex(index)
                        }}
                            className="cursor-pointer">

                            <h1 className={`text-xl px-5
                            ${SelectedItemIndex === index ? 'text-tertiary border-tertiary border-l-4 -ml-[4px] bg-[#2da79137] py-3 sm:-ml-[2px]' : 'text-white'}`}>
                                {project.title2}
                            </h1>
                        </div>
                    ))}
                </div>
                {/* Left Side */}

                {/* Right side */}
                <div className='flex flex-row w-2/3 items-center justify-center h-full md:w-full md:flex-col'>
                    {/* Image&Repository*/}
                    <div className='flex flex-col mr-10 lg:mr-7 sm:ml-7 gap-5 sm:mb-10'>
                        {/* Image */}
                        <div className=''>
                            <img src={projectsrus[SelectedItemIndex].image} alt='' className='h-[250px] w-[400px] mr-[0px] lg:h-[200px] lg:w-[600px] sm:h-[275px] sm:w-[450px] md:h-[275px] md:w-[450px] md:border-double md:border-4 si:h-[225px] si:w-[300px] si:mr-[250px]'/>
                        </div>
                        {/* Image */}
                        {/* Repository */}
                        <div className='flex justify-center'>
                            <a href={projectsrus[SelectedItemIndex].link} target="_blank" rel="noopener noreferrer" className='border-2 border-white text-white text-xl lg:text-base px-10 py-3 sm:mb-10'>
                                Репозиторий
                            </a>
                        </div>
                        {/* Repository */}
                    </div>
                    {/* Image&Repository*/}

                    {/* Text */}
                    <div className='w-2/3 lg:w-full flex flex-col gap-5 md:mt-5'>
                        <h1 className='text-secondary text-3xl lg:text-2xl'>{projectsrus[SelectedItemIndex].title}</h1>
                        {/* Tools&Text */}
                        <div className='flex flex-row w-full items-center gap-3'>
                            <h1 className='text-tertiary text-2xl lg:text-xl'>OS: {projectsrus[SelectedItemIndex].os}</h1>
                            {/* Tools */}
                            <div className='rs:hidden flex flex-row gap-3 justify-center'>
                                {(projectsrus[SelectedItemIndex].skills).map((skill, index) => (
                                    <div className='border border-tertiary py-1.5 px-5 rounded'>
                                        <h1 className='text-tertiary text-xl lg:text-sm'>{skill}</h1>
                                    </div>
                                ))}
                            </div>
                            {/* Tools */}
                        </div>
                        {/* Tools&Text */}
                        <h1 className='text-white text-xl lg:text-base font-serif'>{projectsrus[SelectedItemIndex].description}</h1>
                    </div>
                    {/* Text */}
                </div>
                {/* Right side */}
            </div>
            {/* Entire Box */}
        </div >
    )
}

export default ProjectsRu