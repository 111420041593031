import React from 'react';
import SectionTitle from '../../components/SectionTitle';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

function Courses() {
    const { portfolioData } = useSelector((state) => state.root);
    const { courses } = portfolioData;
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);


    // Scroll fade in
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        })
        const currentRef = domRef.current;
        if (currentRef) {
            observer.observe(currentRef);
        }
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        }
    }, []);


    return (
        <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
            <SectionTitle title="Courses" />
            {/* Entire Box */}
            <div className='flex py-10 gap-20 sm:flex-col items-center sm:py-5 sm:gap-12 de:flex-col de:gap-12'>
                {/* Nav Bar */}
                <div className='flex flex-col gap-10 border-l-4 border-[#59dfb73a] w-1/4 de:flex-row de:w-full de:overflow-x-scroll'>
                    {courses.map((course, index) => (
                        <div onClick={() => {
                            setSelectedItemIndex(index)
                        }}
                            className="cursor-pointer">

                            <motion.h1
                            className={`text-xl px-5
                            ${selectedItemIndex === index
                            ? 'text-tertiary border-tertiary border-l-4 -ml-[4px] sm:-ml-[2px] bg-[#2da79137] py-3'
                            : 'text-white'}`}
                            whileHover={selectedItemIndex !== index ? { scale: 1.1 } : { scale: 1.0 }}
                            >
                                {course.title2}
                            </motion.h1>
                        </div>
                    ))}
                </div>
                {/* Nav Bar */}

                {/* Right side */}
                <motion.div
                className='flex flex-row w-2/3 items-center justify-center h-full sm:w-full sm:flex-col de:w-full md:flex-col'
                key={selectedItemIndex}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.7 }}
                >
                    {/* Text Box*/}
                    <div className='w-2/3 lg:w-full de:w-1/2 md:w-full flex flex-col gap-5 lg:gap-3 mb-5 lg:mb-0 sm:mb-12 md:mb-[35px]'>
                        <h1 className='text-secondary text-4xl lg:text-2xl'>{courses[selectedItemIndex].title}</h1>
                        {/* Tools&Text */}
                        <div className='flex flex-row w-full items-center gap-3'>
                        </div>
                        {/* Tools&Text */}
                        <h1 className='text-white text-xl lg:text-base'>{courses[selectedItemIndex].description}</h1>
                    </div>
                    {/* Text Box*/}

                    {/* Image&Link*/}
                    <div className='flex flex-col de:w-1/2 md:w-full ml-10 lg:ml-7 sm:mr-7 gap-5'>
                        {/* Image */}
                        <div className='flex'>
                            <motion.img
                            src={courses[selectedItemIndex].image}
                            alt=''
                            animate={{
                                borderRadius: ["0rem", "2rem", "0rem"],
                                boxShadow: [
                                    "0 0 30px rgba(0, 0, 0, 0)", 
                                    "0 0 200px rgba(163, 39, 230, 0.9)", 
                                    "0 0 30px rgba(0, 0, 0, 0)"
                                ]
                            }}
                            transition={{
                                delay: 1.5,
                                duration: 2,
                                ease: "easeInOut",
                                repeat: Infinity,
                                repeatType: 'loop',
                                repeatDelay: 1
                            }}
                            className='h-[250px] w-[400px] mr-[0px]
                            lg:h-[200px] lg:w-[600px]
                            sm:h-[275px] sm:w-[450px] sm:border-double sm:border-4
                            md:h-[275px] md:w-[450px] md:border-double md:border-4
                            si:h-[250px] si:w-[300px] si:mr-[250px]'
                            />
                        </div>
                        {/* Image */}
                        {/* Link */}
                        <div className='flex justify-center'>
                            <motion.a
                                href={courses[selectedItemIndex].link}
                                target="_blank" rel="noopener noreferrer"
                                className='border-2 border-white text-white text-bold text-xl lg:text-base px-10 py-3 sm:mb-10'
                                whileHover={{ scale: 1.1, borderRadius: '0.5rem' }}
                            >
                                Source
                            </motion.a>
                        </div>
                        {/* Link */}
                    </div>
                    {/* Image&Link*/}
                </motion.div>
                {/* Right side */}
            </div>
            {/* Entire Box */}
        </div >
    )
}

export default Courses