import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import HomeRu from './pages/Ru'
import AdminPage from './pages/Admin'
import { useEffect, useCallback } from 'react';
import Loader from './components/Loader';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { HideLoading, SetPortfolioData, ShowLoading, ReloadData } from './redux/rootSlice';
import Login from './pages/Admin/Login';
import PasswordGen from './pages/Password';

function App() {
  const { loading, portfolioData, reloadData } = useSelector(state => state.root);
  const dispatch = useDispatch();
  const getPortfolioData = useCallback(async () => {
    try {
      dispatch(ShowLoading());
      const response = await axios.get("/api/portfolio/get-portfolio-data");
      dispatch(SetPortfolioData(response.data));
      dispatch(ReloadData(false));
      dispatch(HideLoading());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!portfolioData) {
      getPortfolioData();
    }
  }, [portfolioData, getPortfolioData]);

  useEffect(() => {
    if (reloadData){
      getPortfolioData()
    }
  }, [reloadData, getPortfolioData])

  return (
    <BrowserRouter>
      {loading ? <Loader /> : null}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ru" element={<HomeRu />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin-login" element={<Login />} />
        <Route path="/password-gen" element={<PasswordGen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;