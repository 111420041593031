import React from 'react';
import SectionTitle from '../../components/SectionTitle';
import { useSelector } from 'react-redux';

function CoursesRu() {
    const { portfolioData } = useSelector((state) => state.root);
    const { coursesrus } = portfolioData;
    const [SelectedItemIndex, setSelectedItemIndex] = React.useState(0);


    // Scroll fade in
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        })
        const currentRef = domRef.current; // Copy the ref value to a variable
        if (currentRef) {
            observer.observe(currentRef);
        }
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        }
    }, []);


    return (
        <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
            <SectionTitle title="Курсы" />
            {/* Entire Box */}
            <div className='flex py-10 gap-20 sm:flex-col items-center sm:py-5 sm:gap-12 md:flex-col md:gap-12'>
                {/* Left Side */}
                <div className='flex sm:overflow-x-scroll flex-col gap-10 border-l-4 border-[#59dfb73a] w-1/4 sm:flex-row sm:w-full md:flex-row md:w-full md:overflow-x-scroll'>
                    {coursesrus.map((course, index) => (
                        <div onClick={() => {
                            setSelectedItemIndex(index)
                        }}
                            className="cursor-pointer">

                            <h1 className={`text-xl px-5
                            ${SelectedItemIndex === index ? 'text-tertiary border-tertiary border-l-4 -ml-[4px] sm:-ml-[2px] bg-[#2da79137] py-3' : 'text-white'}`}>
                                {course.title2}
                            </h1>
                        </div>
                    ))}
                </div>
                {/* Left Side */}

                {/* Right side */}
                <div className='flex flex-row w-2/3 items-center justify-center h-full sm:w-full sm:flex-col md:w-full md:flex-col'>
                    {/* Text Box*/}
                    <div className='w-2/3 lg:w-full flex flex-col gap-5 sm:mb-12 md:mb-[35px]'>
                        <h1 className='text-secondary text-3xl lg:text-2xl'>{coursesrus[SelectedItemIndex].title}</h1>
                        {/* Tools&Text */}
                        <div className='flex flex-row w-full items-center gap-3'>
                        </div>
                        {/* Tools&Text */}
                        <h1 className='text-white text-xl lg:text-base'>{coursesrus[SelectedItemIndex].description}</h1>
                    </div>
                    {/* Text Box*/}

                    {/* Image&Link*/}
                    <div className='flex flex-col ml-10 lg:ml-7 sm:mr-7 gap-5 mt-3'>
                        {/* Image */}
                        <div className='flex'>
                            <img src={coursesrus[SelectedItemIndex].image} alt='' className='h-[250px] w-[400px] mr-[0px] lg:h-[200px] lg:w-[600px] sm:h-[275px] sm:w-[450px] sm:border-double sm:border-4 md:h-[275px] md:w-[450px] md:border-double md:border-4 si:h-[225px] si:w-[300px] si:mr-[250px]'/>
                        </div>
                        {/* Image */}
                        {/* Link */}
                        <div className='flex justify-center'>
                            <a href={coursesrus[SelectedItemIndex].link} target="_blank" rel="noopener noreferrer" className='border-2 border-white text-white text-xl lg:text-base px-10 py-3 sm:mb-10'>
                                Источник
                            </a>
                        </div>
                        {/* Link */}
                    </div>
                    {/* Image&Link*/}
                </div>
                {/* Right side */}
            </div>
            {/* Entire Box */}
        </div >
    )
}

export default CoursesRu