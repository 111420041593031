import React from 'react';
import SectionTitle from '../../components/SectionTitle';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

function Projects() {
    const { portfolioData } = useSelector((state) => state.root);
    const { projects } = portfolioData;
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);


    // Scroll fade in
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        })
        const currentRef = domRef.current;
        if (currentRef) {
            observer.observe(currentRef);
        }
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        }
    }, []);


    return (
        <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
            <SectionTitle title="Projects" />
            {/* Entire Box */}
            <div className='flex py-10 gap-20 items-center sm:flex-col sm:gap-12 sm:py-5 de:flex-col de:gap-12'>
                {/* Nav Bar */}
                <div className='flex flex-col gap-10 border-l-4 border-[#59dfb73a] w-1/4 sm:flex-row sm:w-full sm:overflow-x-scroll de:flex-row de:w-full de:overflow-x-scroll'>
                    {projects.map((project, index) => (
                        <div onClick={() => {
                            setSelectedItemIndex(index)
                        }}
                        className="cursor-pointer">

                            <motion.h1
                            className={`text-xl px-5
                            ${selectedItemIndex === index 
                            ? 'text-tertiary border-tertiary border-l-4 -ml-[4px] bg-[#2da79137] py-3 sm:-ml-[2px]'
                            : 'text-white'}`}
                            whileHover={selectedItemIndex !== index ? { scale: 1.1 } : { scale: 1.0 }}
                            >
                                {project.title2}
                            </motion.h1>
                        </div>
                    ))}
                </div>
                {/* Nav Bar */}

                {/* Right side */}
                <motion.div className='flex flex-row w-2/3 items-center justify-center h-full de:w-full md:flex-col'
                key={selectedItemIndex}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.7 }}
                >
                    {/* Image&Repository*/}
                    <div className='flex flex-col de:w-1/2 md:w-full mr-10 lg:mr-7 sm:ml-7 gap-5 sm:mb-10'>
                        {/* Image */}
                        <div>
                            <motion.img
                            src={projects[selectedItemIndex].image}
                            alt=''
                            animate={{
                                borderRadius: ["0rem", "2rem", "0rem"],
                                boxShadow: [
                                    "0 0 30px rgba(0, 0, 0, 0)", 
                                    "0 0 200px rgba(25, 59, 235, 0.9)", 
                                    "0 0 30px rgba(0, 0, 0, 0)"
                                ]
                            }}
                            transition={{
                                duration: 2,
                                ease: "easeInOut",
                                repeat: Infinity,
                                repeatType: 'loop',
                                repeatDelay: 1
                            }}
                            className='h-[250px] w-[400px] mr-[0px] 
                            lg:h-[200px] lg:w-[600px]
                            sm:h-[275px] sm:w-[450px]
                            md:h-[275px] md:w-[450px] md:border-double md:border-4
                            si:h-[250px] si:w-[300px] si:mr-[250px]'/>
                        </div>
                        {/* Image */}
                        {/* Repository */}
                        <motion.div className='flex justify-center'>
                            <motion.a
                            href={projects[selectedItemIndex].link}
                            target="_blank" rel="noopener noreferrer"
                            className='border-2 border-white text-white text-bold text-xl lg:text-base px-10 py-3 sm:mb-10'
                            whileHover={{ scale: 1.1, borderRadius: "0.5rem" }}
                            >
                                Repository
                            </motion.a>
                        </motion.div>
                        {/* Repository */}
                    </div>
                    {/* Image&Repository*/}

                    {/* Text */}
                    <div className='w-2/3 de:w-1/2 md:w-full lg:w-full flex flex-col gap-5 md:mt-5'>
                        <h1 className='text-secondary text-4xl lg:text-2xl'>{projects[selectedItemIndex].title}</h1>
                        {/* Tools&Text */}
                        <div className='flex flex-row w-full items-center gap-3'>
                            <h1 className='text-tertiary text-2xl lg:text-xl'>OS: {projects[selectedItemIndex].os}</h1>
                            {/* Tools */}
                            <div className='rs:hidden flex flex-row gap-3 justify-center'>
                                {(projects[selectedItemIndex].skills).map((skill, index) => (
                                    <div className='border border-tertiary py-1.5 px-5 rounded'>
                                        <h1 className='text-tertiary text-xl lg:text-sm'>{skill}</h1>
                                    </div>
                                ))}
                            </div>
                            {/* Tools */}
                        </div>
                        {/* Tools&Text */}
                        <h1 className='text-white text-xl lg:text-base'>{projects[selectedItemIndex].description}</h1>
                    </div>
                    {/* Text */}
                </motion.div>
                {/* Right side */}
            </div>
            {/* Entire Box */}
        </div >
    )
}

export default Projects