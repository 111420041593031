import React from 'react'
import SectionTitle from '../../components/SectionTitle'
import { useSelector } from 'react-redux';

function ExperiencesRu() {
    const { portfolioData } = useSelector((state) => state.root);
    const { experiencesrus } = portfolioData;
    const [SelectedItemIndex, setSelectedItemIndex] = React.useState(0);


    // Scroll fade in
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        })
        const currentRef = domRef.current; // Copy the ref value to a variable
        if (currentRef) {
            observer.observe(currentRef);
        }
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        }
    }, []);


    return (
        <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
            <SectionTitle title="Опыт" />
            {/* Period */}
            <div className='flex py-10 gap-20 items-center sm:flex-col sm:py-5'>
                <div className='flex sm:overflow-x-scroll flex-col gap-10 border-l-4 border-[#59dfb73a] w-1/4 sm:flex-row sm:w-full'>
                    {experiencesrus.map((experience, index) => (
                        <div onClick={() => {
                            setSelectedItemIndex(index)
                        }}
                            className="cursor-pointer">

                            <h1 className={`text-xl px-5
                            ${SelectedItemIndex === index ? 'text-tertiary border-tertiary border-l-4 -ml-[4px] sm:-ml-[2px] bg-[#2da79137] py-3' : 'text-white'}`}>
                                {experience.period}
                            </h1>
                        </div>
                    ))}
                </div>

                {/* Description */}
                <div className='flex flex-col gap-5 w-2/3 sm:w-full'>
                    <h1 className='text-secondary text-3xl lg:text-2xl'>{experiencesrus[SelectedItemIndex].title}</h1>
                    <h1 className='text-tertiary text-2xl lg:text-xl'>{experiencesrus[SelectedItemIndex].company}</h1>
                    <h1 className='text-white text-xl lg:text-base font-serif'>{experiencesrus[SelectedItemIndex].description}</h1>
                </div>
                {/* Description */}
            </div>
            {/* Period */}
        </div >
    )
}

export default ExperiencesRu